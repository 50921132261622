<template lang="">
  <v-autocomplete
    v-model="genderSelected"
    :items="gender"
    outlined
    label="Sexo"
    @change="setGender"
  ></v-autocomplete>
</template>
<script>
import { gender } from "../../data/gender";
export default {
  name: "SelectGender",
  props: ["selected"],
  data() {
    return {
      genderSelected: this.selected,
      gender: gender,
    };
  },
  methods: {
    setGender() {
      this.$emit("setGender", this.genderSelected);
    },
  },
  watch: {
    $props: {
      handler() {
        this.genderSelected = this.selected;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
