<template lang="">
  <div>
    <LoaderLinear />
    <v-card elevation="0" class="pa-5 ma-2">
      <form @submit.prevent="sendForm" id="formUser">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.namePerson.$model"
              label="Nombre y Apellido / Razón Social (*)"
              :error-messages="namePersonErrors"
              @blur="$v.namePerson.$touch()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.identifier.$model"
              label="DNI/CUIT/CUIL (*)"
              :error-messages="identifierErrors"
              @blur="$v.identifier.$touch()"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="phone"
              label="Teléfono"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              outlined
              v-model="$v.email.$model"
              :error-messages="emailErrors"
              @blur="$v.email.$touch()"
              label="E-Mail (*)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <SelectGender @setGender="setGender" :selected="genderSelected" />
          </v-col>
          <v-col cols="12" md="6">
            <SelectTypeUser @setTypeUser="setTypeUser" />
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pa-0"
            v-for="permission in persons.allPermissions"
            :key="permission.id"
          >
            <v-checkbox
              v-model="selectedPermission"
              :label="permission.name"
              :value="permission.name"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Button
              :disabled="$v.$invalid"
              classes="mr-4 float-right fvl-btn green-primary"
              type="submit"
              name="Guardar"
            />
            <router-link :to="goToBack">
              <Button
                :disabled="false"
                classes="mr-4 float-right fvl-btn green-military"
                type="button"
                name="Volver"
              />
            </router-link>
          </v-col>
        </v-row>
      </form>
      <Overlay />
    </v-card>
    <SnackBar />
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";
import { beginRequest, endRequest, links } from "../../helpers";
import { LoaderLinear, SnackBar, Overlay, Button } from "../_commons";
import SelectGender from "../gender/SelectGender.vue";
import SelectTypeUser from "../typeUser/SelectTypeUser.vue";
import { getPermissions } from "../_selectors/permissions";

export default {
  name: "FormUser",
  props: ["actionForm"],
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
    SelectGender,
    SelectTypeUser,
    Button,
  },
  mixins: [validationMixin],
  validations: {
    namePerson: { required, minLength: minLength(3) },
    email: { email, maxLength: maxLength(100), required },
    identifier: { required, minLength: minLength(8), maxLength: maxLength(13) },
  },
  data() {
    return {
      namePerson: "",
      goToBack: links.user.list,
      identifier: "",
      email: "",
      phone: "",
      genderSelected: "",
      selectedPermission: [],
    };
  },
  methods: {
    sendForm() {
      const formData = {
        namePerson: this.namePerson,
        identifier: this.identifier,
        email: this.email,
        phone: this.phone,
        gender: this.genderSelected,
        permissions: this.selectedPermission,
        types_person_id: this.persons.typeSelected,
      };
      this.$emit("sendForm", formData);
    },
    setGender(selected) {
      // evento emitido en el onchange del select gender
      this.genderSelected = selected;
    },
    setTypeUser() {
      this.selectedPermission = this.getPermissionsByType;
    },

    resetFormMethod() {
      // EVENTO EMITIDO DESDE EL PADRE (SOLO CUANDO EL ACTION ES CREATE)
      (this.namePerson = ""),
        (this.identifier = ""),
        (this.email = ""),
        (this.phone = ""),
        (this.genderSelected = ""),
        this.$store.dispatch("settypeSelectedAction", 1);
        this.$v.$reset();
    },
  },
  computed: {
    ...mapState({
      persons: (state) => state.persons,
      usersStore: (state) => state.users,
    }),
    ...mapGetters(["getPermissionsByType"]),
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.maxLength &&
        errors.push("La longitud máxima es de 100 caracteres");
      !this.$v.email.email && errors.push("Debe ingresar un E-mail");
      !this.$v.email.required && errors.push("El campo es requerido");
      return errors;
    },
    namePersonErrors() {
      const errors = [];
      if (!this.$v.namePerson.$dirty) return errors;
      !this.$v.namePerson.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.namePerson.required && errors.push("El campo es requerido");
      return errors;
    },
    identifierErrors() {
      const errors = [];
      if (!this.$v.identifier.$dirty) return errors;
      !this.$v.identifier.minLength &&
        errors.push("La longitud minima es de 8 caracteres");
      !this.$v.identifier.maxLength &&
        errors.push("La longitud maxima es de 13 caracteres");
      !this.$v.identifier.required && errors.push("El campo es requerido");
      return errors;
    },
  },
  async created() {
    await beginRequest();
    await this.$store.dispatch("setTypesPersonAction");
    const { status, snack } = await this.$store.dispatch(
      "setAllPermissionsAction"
    );
    if (this.actionForm === "create") {
      this.setTypeUser(); // USA EL GETTER PARA TRAER PERMISOS SELECCIONADOS
    }
    if (this.actionForm === "Edit") {
      const { snack, status } = await this.$store.dispatch(
        "setUserSelectedDataAction",
        this.$route.params.iduser
      );
      this.namePerson = this.usersStore.userSelectedData.get_person.name;
      this.identifier = this.usersStore.userSelectedData.get_person.identifier;
      this.email = this.usersStore.userSelectedData.email;
      this.phone = this.usersStore.userSelectedData.get_person.phone;
      this.genderSelected = this.usersStore.userSelectedData.get_person.gender;
      if (this.actionForm === "Edit") {
        this.selectedPermission = getPermissions(
          this.usersStore.userSelectedData.permissions
        );
        this.$store.dispatch(
          "settypeSelectedAction",
          this.usersStore.userSelectedData.get_person.get_type_person.id
        );
      }
      await endRequest(snack, status);
      return;
    }
    await endRequest(snack, status);
  },
};
</script>
