<template lang="">
  <v-container
    v-if="
      user.permissions.indexOf(permissionsAll.user_update) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <BreadCrumbsVue :items="itemsBreadCrumbs" />
    <FromUser @sendForm="sendForm" :actionForm="actionForm" />
  </v-container>
</template>
<script>
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import BreadCrumbsVue from "../../components/_commons/BreadCrumbs.vue";
import FromUser from "../../components/users/FromUser.vue";
import { mapState } from "vuex";
import permissions from "../../helpers/permissions";
export default {
  name: "UserCreate",
  components: {
    BreadCrumbsVue,
    FromUser,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.userEdit,
      actionForm: "Edit",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async sendForm(formData) {
      await beginRequest();
      const payload = {
        id: this.$route.params.iduser,
        data: formData,
      };
      const { snack, status } = await this.$store.dispatch(
        "updateUser",
        payload
      );
      await endRequest(snack, status);
    },
  },
};
</script>
