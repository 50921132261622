<template lang="">
  <div>
    <v-autocomplete
      :value="this.persons.typeSelected"
      :items="getTypesPerson"
      label="Tipo de Usuario (*)"
      @change="setTypeUser"
      outlined
    ></v-autocomplete>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
export default {
  methods: {
    setTypeUser(e) {
      this.$store.dispatch('settypeSelectedAction',e);
      this.$emit("setTypeUser");
    },
  },
  computed: {
    ...mapState({
      persons: (state) => state.persons,
    }),
    ...mapGetters(["getTypesPerson"]),
  },  
};
</script>
